import { defu } from 'defu'
import { ALL_LOB } from '~/constants'
import { useWorkspace } from '~/stores/workspace'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const connectorId = to.query.lob as string
  const currentUser = useCurrentUser()
  const workspace = useWorkspace()
  const { setUrlParams, activeUrlParams, deleteUrlParam, patchUrlParams } =
    useUrlParamsState()

  if (to.meta.filters && Array.isArray(to.meta.filters)) {
    const updatedFilters: Record<string, string[]> = {}
    for (const filterName of [
      ...to.meta.filters,
      'lob',
      'start_date',
      'end_date',
    ]) {
      if (/^claims/.test(from.name) && filterName === 'insight_mode') {
        deleteUrlParam(filterName)
      } else if (to.query[filterName]) {
        updatedFilters[filterName] = to.query[filterName] as string[]
      }
    }

    patchUrlParams(updatedFilters)
  }

  if (!import.meta.client) {
    return
  }

  if (connectorId) {
    setUrlParams('lob', connectorId)
    history.replaceState(
      { query: { ...activeUrlParams, lob: workspace.connectorId } },
      to.path,
    )
    workspace.$patch({
      connectorId,

      previousConnectorId: '',
    })
  }

  if (connectorId && connectorId !== workspace.connectorId) {
    await workspace.findAndSetConnector(connectorId, {
      force: true,
      middleware: true,
    })
    return
  }

  if (workspace.connectorId === ALL_LOB || !workspace.connectorId) {
    await workspace.findAndSetConnector(workspace.previousConnectorId, {
      middleware: true,
    })
  }

  if (
    workspace.underMaintenance &&
    !guard(
      'ANY',
      [PERMISSIONS.readSystem, PERMISSIONS.writeSystem],
      workspace.userPermissions,
    )
  ) {
    return navigateTo({ path: '/account' }, { replace: true })
  }

  if (!connectorId && workspace.connectorId) {
    const query = defu(
      { ...activeUrlParams, lob: workspace.connectorId },
      to.query,
    )
    history.replaceState({ query }, to.path)

    return navigateTo({ path: to.path, query }, { replace: true })
  }

  if (!currentUser.value?.connectors) {
    await workspace.waitForReady()
    if (!workspace.user) {
      return navigateTo({ path: '/signin' }, { replace: true })
    }
    return navigateTo({ path: '/account', query: to.query }, { replace: true })
  }
})
